import React from "react";
import notFound from "../../assets/no-search-found.png";

const NoInfluencerFound = () => {
  return (
    <div className="not_found">
      <img src={notFound} alt="no search found" />
      <h4>No results found</h4>
      <p>
        We couldn't find what you searched for. <br />
        Try searching again
      </p>
    </div>
  );
};

export default NoInfluencerFound;
