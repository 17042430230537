import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import updatingGif from "../assets/update-spinner.gif";
import { GrUpdate } from "react-icons/gr";
import { Tooltip } from "@mui/material";
import { baseUrl } from "../apiConfig";

function InstagramProfile({ username, id }) {
  const [loading, setLoading] = useState(false);

  const fetchProfile = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/instagram-data/${username}`);
      await updateData(response?.data?.data);
      toast.success("Profile data updated successfully!");
    } catch (error) {
      console.error("Error fetching Instagram profile:", error);
      toast.error("Failed to fetch Instagram profile.");
    } finally {
      setLoading(false);
    }
  };

  const notifyError = (err) => toast.error(err);

  const updateData = async (givendata) => {
    if (!givendata || !givendata.user) {
      console.error("Invalid data received for update.");
      return;
    }

    const data = {
      Name: givendata.user.full_name,
      Followers: givendata.user.edge_followed_by?.count,
      profilePic: givendata.user.profile_pic_url,
    };

    try {
      const response = await fetch(`${baseUrl}/influencer/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.message || "Network response was not ok");
      }
    } catch (error) {
      console.log(error.message || "An error occurred");
      notifyError(error.message || "An error occurred");
    }
  };

  return (
    <>
      {loading ? (
        <img src={updatingGif} alt="updating" className="loading_spinner" />
      ) : (
        <Tooltip title="Update" placement="top">
          <button
            className="btn btn_edit p-0 ms-3"
            onClick={fetchProfile}
            disabled={loading}
          >
            <GrUpdate style={{ fontSize: "14px" }} />
          </button>
        </Tooltip>
      )}
    </>
  );
}

export default InstagramProfile;
