import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";

const useGetInfluencerById = (id) => {
  const [influencer, setInfluencer] = useState([]);
  const url = window.location.href;

  const getData = async () => {
    try {
      const response = await fetch(`${baseUrl}/influencer/${id}`);
      const data = await response.json();
      setInfluencer(data);
    } catch (error) {
      console.error(error);
      setInfluencer([]);
    }
  };

  useEffect(() => {
    if (url.includes("/edit/") || url.includes("/influencer-profile/")) {
      getData();
    }
  }, [id, url]);

  return [influencer];
};

export default useGetInfluencerById;
