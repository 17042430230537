import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const TopFilters = ({
  name,
  setName,
  userName,
  setUserName,
  location,
  setLocation,
}) => {
  const user = useSelector((store) => store.user);
  const {
    userData: {
      permissions: { searchByName, searchByUsername, searchByLocation } = {},
      isAdmin,
    } = {},
  } = user || {};

  const [debouncedName, setDebouncedName] = useState(name);
  const [debouncedUsername, setDebouncedUsername] = useState(userName);
  const [debouncedLocation, setDebouncedLocation] = useState(location);

  useEffect(() => {
    const timer = setTimeout(() => {
      setName(debouncedName);
    }, 500);

    return () => clearTimeout(timer);
  }, [debouncedName, setName]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setUserName(debouncedUsername);
    }, 500);

    return () => clearTimeout(timer);
  }, [debouncedUsername, setUserName]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLocation(debouncedLocation);
    }, 500);

    return () => clearTimeout(timer);
  }, [debouncedLocation, setLocation]);

  return (
    <div className="filter_top mt-3">
      {(isAdmin || searchByName) && (
        <input
          type="text"
          placeholder="Search by name"
          value={debouncedName}
          onChange={(e) => setDebouncedName(e.target.value)}
        />
      )}
      {(isAdmin || searchByUsername) && (
        <input
          type="text"
          placeholder="Search by username"
          value={debouncedUsername}
          onChange={(e) => setDebouncedUsername(e.target.value)}
        />
      )}
      {(isAdmin || searchByLocation) && (
        <input
          type="text"
          placeholder="Search by location"
          value={debouncedLocation}
          onChange={(e) => setDebouncedLocation(e.target.value)}
        />
      )}
    </div>
  );
};

export default TopFilters;
