import * as React from "react";
import { BarChart } from "@mui/x-charts";
import { useSelector } from "react-redux";

function LineGraph() {
  const { influencers } = useSelector(
    (store) => store.influencers.influencersList
  ) || { influencers: [] };

  const categoryCounts = {};
  influencers?.forEach((influencer) => {
    influencer?.primaryCategory?.forEach((category) => {
      const normalizedCategory = category?.toLowerCase();
      if (normalizedCategory && normalizedCategory !== "na") {
        if (categoryCounts[normalizedCategory]) {
          categoryCounts[normalizedCategory] += 1;
        } else {
          categoryCounts[normalizedCategory] = 1;
        }
      }
    });
  });

  const categories = Object.keys(categoryCounts).map(
    (category) => category.charAt(0).toUpperCase() + category.slice(1)
  );
  const data = Object.values(categoryCounts);

  return (
    <div className="linegraph_main">
      <BarChart
        xAxis={[
          {
            scaleType: "band",
            data: categories,
          },
        ]}
        series={[
          {
            data: data,
            color: "rgb(128, 196, 233)",
          },
        ]}
        width={600}
        height={350}
      />
    </div>
  );
}

export default LineGraph;
