import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";

const useGetUserById = (id) => {
  const [userData, setUserData] = useState([]);
  const url = window.location.href;
  const getData = async () => {
    const response = await fetch(`${baseUrl}/user-data/${id}`);
    const data = await response.json();
    setUserData(data);
  };

  useEffect(() => {
    if (!url.includes("create-user")) {
      getData();
    }
  }, [id, url]);
  return [userData];
};

export default useGetUserById;
