import axios from "axios";
import React, { useRef, useState } from "react";
import { baseUrl } from "../../apiConfig";
import { IoIosWarning } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import Loader from "./Loader";
import { useSelector } from "react-redux";

const ForgotPassword = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const newPass = useRef(null);
  const otp = useRef(null);
  const confirmNewPass = useRef(null);
  const email = useRef(null);
  const userData = useSelector((store) => store.user.userData);
  const url = window.location.href;
  const isLoginPage = url.includes("/login");

  const requestOtp = async () => {
    setLoading(true);
    try {
      await axios.post(`${baseUrl}/request-otp`, {
        email: isLoginPage ? email?.current?.value : userData?.email,
      });
      setOtpSent(true);
      setStatus("OTP sent successfully!");
    } catch (error) {
      setStatus("Failed to send OTP");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    if (newPass.current.value !== confirmNewPass.current.value) {
      setStatus("Passwords do not match");
      return;
    }

    try {
      await axios.post(`${baseUrl}/verify-otp`, {
        email: userData?.email,
        otp: otp.current.value,
      });

      await axios.post(`${baseUrl}/reset-password`, {
        email: userData?.email,
        newPassword: newPass.current.value,
      });

      setStatus("Password updated successfully!");
    } catch (error) {
      setStatus("Failed to update password or OTP verification failed");
    }
  };

  return (
    <>
      <button
        type="button"
        className="forgt_pass mt-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Forgot Password?
      </button>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3
                className="modal-title modal_title fs-5"
                id="exampleModalLabel"
              >
                Reset New Password
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {!otpSent ? (
                <>
                  {!isLoginPage
                    ? !loading && <p>OTP will send to {userData?.email}</p>
                    : !loading && (
                        <>
                          <p className="text-start light_text">
                            Enter your email
                          </p>
                          <input
                            ref={email}
                            type="text"
                            placeholder="Email"
                            className="mt-2 pswrd_input w-100"
                          />
                        </>
                      )}
                  {!loading ? (
                    <button
                      type="button"
                      className="btn update_password ms-0 mt-3"
                      onClick={requestOtp}
                    >
                      Send OTP
                    </button>
                  ) : (
                    <Loader />
                  )}
                </>
              ) : (
                <form onSubmit={handleSubmitOtp}>
                  <input
                    ref={otp}
                    type="text"
                    placeholder="Enter OTP"
                    className="mt-2 pswrd_input w-100"
                  />
                  <input
                    ref={newPass}
                    type="password"
                    placeholder="New Password"
                    className="mt-3 pswrd_input w-100"
                  />
                  <input
                    ref={confirmNewPass}
                    type="password"
                    placeholder="Confirm New Password"
                    className="mt-3 pswrd_input w-100"
                  />
                  <button
                    type="button"
                    className="btn cancel_password mt-3"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn update_password mt-3 ms-3"
                  >
                    Update Password
                  </button>
                </form>
              )}
              <div>
                {status && (
                  <p
                    className={
                      status.includes("successfully")
                        ? "mt-3 text-success text-bold"
                        : "mt-3 text-danger text-bold"
                    }
                  >
                    {status.includes("successfully") ? (
                      <>
                        <TiTick style={{ fontSize: "26px" }} />
                        {status}
                      </>
                    ) : (
                      <>
                        <IoIosWarning /> {status}
                      </>
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
