import React from "react";
import { MdOutlinePreview } from "react-icons/md";
import { TbBrandCampaignmonitor } from "react-icons/tb";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const CampaignSidebar = () => {
  const user = useSelector((store) => store.user);
  const {
    userData: {
      permissions: { createCampaign, viewCampaign } = {},
      isAdmin,
    } = {},
  } = user || {};
  return (
    <div
      className="filter_left setting_sidebar mt-3"
      style={{ height: "86vh", width: "19%" }}
    >
      <ul className="nav">
        {(isAdmin || createCampaign) && (
          <li className="nav-item">
            <NavLink to={"/campaign/create-campaign"} className="nav-link">
              <TbBrandCampaignmonitor className="icon" /> Create campaign
            </NavLink>
          </li>
        )}
        {(isAdmin || viewCampaign) && (
          <li className="nav-item">
            <NavLink to={"/campaign/all-campaigns"} className="nav-link">
              <MdOutlinePreview className="icon" /> View all campaign
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
};

export default CampaignSidebar;
