import React, { Suspense, lazy } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import Sidebar from "../Sidebar";
import useGetInfluencerById from "../../utils/useGetInfluencerById";
import ShimmerHeader from "./ShimmerHeader";
import ShimmerBody from "./ShimmerBody";
import DeleteModal from "../DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "../../apiConfig";
const ProfileHeader = lazy(() => import("./ProfileHeader"));
const ProfileHighlights = lazy(() => import("./ProfileHighlights"));
const PersonalInfo = lazy(() => import("./PersonalInfo"));
const OtherInfo = lazy(() => import("./OtherInfo"));

const InfluencerProfile = () => {
  const params = useParams();
  const { id } = params;
  const [influencer] = useGetInfluencerById(id);
  const navigate = useNavigate();

  const deleteInfluencer = async () => {
    try {
      const response = await fetch(`${baseUrl}/all-influencers/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      toast.success(result.message);
      navigate("/influencers");
    } catch (error) {
      console.error("Error deleting influencer:", error);
      toast.error("Error deleting influencer");
    }
  };
  console.log(influencer);

  return (
    <div className="wrapper">
      <Sidebar />
      <ScrollToTop />
      <ToastContainer />
      <div className="main-panel">
        <div className="container add_data_main">
          <div className="add_data_home">
            <Suspense fallback={<ShimmerHeader />}>
              <ProfileHeader influencer={influencer} id={id} />
            </Suspense>
            <Suspense fallback={<ShimmerBody height={"111px"} />}>
              <ProfileHighlights influencer={influencer} />
            </Suspense>
            <div className="divider_line"></div>
            <h2 className="heading2">Personal Informations</h2>
            <Suspense fallback={<ShimmerBody height={"232px"} />}>
              <PersonalInfo influencer={influencer} />
            </Suspense>
            <div className="divider_line"></div>
            <h2 className="heading2">Others</h2>
            <Suspense fallback={<ShimmerBody height={"232px"} />}>
              <OtherInfo influencer={influencer} />
            </Suspense>
            {/* <DeleteInfluencer /> */}
            <DeleteModal deleteHandler={deleteInfluencer} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerProfile;
