import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { PieChart } from "@mui/x-charts/PieChart";
import { useSelector } from "react-redux";

function CircleGraph() {
  const { influencers, total } = useSelector(
    (store) => store.influencers.influencersList
  ) || { influencers: [], total: 0 };

  const types = [
    { label: "Nano", color: "var(--primary-color)" },
    { label: "Micro", color: "#80C4E9" },
    { label: "Mid Tier", color: "#87bc45" },
    { label: "Macro", color: "#f46a9b" },
    { label: "Mega", color: "#ef9b20" },
    { label: "Celebrity", color: "#edbf33" },
    { label: "Others", color: "#ede15b" },
  ];

  const data2 = types.map((type) => {
    const count = influencers.filter((item) => {
      if (type.label === "Others") {
        return item.Type.toLowerCase() === "";
      }
      return item.Type.toLowerCase() === type.label.toLowerCase();
    });
    return {
      label: type.label,
      value: Math.round((count.length / total) * 100),
      color: type.color,
    };
  });

  const series = [
    {
      innerRadius: 30,
      outerRadius: 100,
      paddingAngle: 4,
      cornerRadius: 5,
      startAngle: -90,
      endAngle: 180,
      cx: 150,
      cy: 150,
      id: "series-1",
      data: data2,
    },
  ];

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={{ xs: 0, md: 4 }}
      sx={{ width: "100%" }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <PieChart
          series={series}
          width={400}
          height={300}
          slotProps={{
            legend: { hidden: true },
          }}
        />
        <div className="pie_overview">
          {types.map((type) => {
            const count = influencers.filter((item) => {
              if (type.label === "Others") {
                return item.Type.toLowerCase() === "";
              }
              return item.Type.toLowerCase() === type.label.toLowerCase();
            }).length;

            return (
              <div key={type.label}>
                <div
                  className="orange_box"
                  style={{ backgroundColor: `${type.color}` }}
                ></div>
                <div>
                  <h6 className="mb-0">{count}</h6> <p>{type.label}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Box>
    </Stack>
  );
}

export default CircleGraph;
