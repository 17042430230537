import React from "react";

const InfluencerLoader = () => {
  const cards = Array.from({ length: 4 });
  return (
    <div className="row">
      {cards.map((_, index) => (
        <div className="col-md-6" key={index}>
          <div className="infl_card_box is-loading">
            <div className="image"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InfluencerLoader;
