import { createSlice } from "@reduxjs/toolkit";

export const allInfluencersSlice = createSlice({
  name: "influencers",
  initialState: {
    isLoading: false,
  },
  reducers: {
    addInfluencers: (state, action) => {
      state.influencersList = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { addInfluencers, setLoading } = allInfluencersSlice.actions;
export default allInfluencersSlice.reducer;
