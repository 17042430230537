import React from "react";
import "./InfluencerProfile.scss";

const ShimmerHeader = () => {
  return (
    <div className="profile_header">
      <div className="go_back_dummy shimmer"></div>
      <div className="profile_pic_main">
        <div className="profile_pic shimmer"></div>
        <div className="shimmer edit_dummy w-100 mt-3"></div>
      </div>
      <div className="shimmer edit_dummy"></div>
    </div>
  );
};

export default ShimmerHeader;
