import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token") || null,
  userData: JSON.parse(localStorage.getItem("userData")) || {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.token = action.payload.token;
      state.userData = action.payload.userData;
    },
    removeUser: (state) => {
      state.token = null;
      state.userData = {};
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userData");
    },
  },
});

export const { addUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
