import React, { useEffect, useRef, useState } from "react";
import ScrollToTop from "../ScrollToTop";
import Sidebar from "../Sidebar";
import { IoIosArrowRoundBack, IoIosEyeOff, IoMdEye } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../apiConfig";
import { toast, ToastContainer } from "react-toastify";
import useGetUserById from "../../utils/useGetUserById";
import Loader from "../settings/Loader";

const CreateUser = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const name = useRef(null);
  const userName = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const navigate = useNavigate();
  const url = window.location.href;
  const params = useParams();
  const id = params.id;

  const [userData] = useGetUserById(id);

  const notifySuccess = () =>
    toast.success(
      url.includes("/edit")
        ? "Updated Successfully"
        : "User created successfully!!"
    );

  const notifyError = (err) => toast.error(err);

  useEffect(() => {
    if (userData.length !== 0 && url.includes("/edit")) {
      name.current.value = userData.name || "";
      userName.current.value = userData.username || "";
      email.current.value = userData.email || "";
      password.current.value = userData.password || "";
    }
  }, [userData, url]);

  const createUserHandler = async () => {
    const userData = {
      name: name.current.value,
      username: userName.current.value,
      email: email.current.value,
      password: password.current.value,
    };

    const requestOptions = {
      method: url.includes("/edit") ? "PATCH" : "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    };

    try {
      setLoading(true);
      const response = await fetch(
        url.includes("/edit")
          ? `${baseUrl}/user-data/${id}`
          : `${baseUrl}/register`,
        requestOptions
      );
      const responseData = await response.json();

      if (response.ok) {
        notifySuccess();
        navigate("/users");
      } else {
        notifyError(responseData.message || "Error creating/updating user");
      }
    } catch (error) {
      console.error("Error creating/updating user:", error);
      notifyError("Error creating/updating user");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const showPasswordHandler = () => {
    setShowPassword((prev) => !prev);
  };
  console.log(loading);
  return (
    <div className="wrapper">
      <Sidebar />
      <ScrollToTop />
      <div
        className="main-panel"
        style={{ backgroundColor: "rgb(244, 248, 251)" }}
      >
        <ToastContainer />
        <div className="dashboard_main">
          <div className="dashboard_header">
            <Link to={"/users"} className="go_back">
              <IoIosArrowRoundBack className="icon" />
            </Link>
            <h4>Create New User</h4>
          </div>
          <div className="add_data_main mt-3">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="form-container text-center login_form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-group">
                        <input
                          ref={name}
                          type="text"
                          className="form-control"
                          placeholder="Name*"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          ref={userName}
                          type="text"
                          className="form-control"
                          placeholder="Username*"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          ref={email}
                          type="email"
                          className="form-control"
                          placeholder="Email*"
                          required
                        />
                      </div>
                      <div className="form-group position-relative">
                        <input
                          ref={password}
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Password*"
                          required
                        />
                        {!showPassword ? (
                          <IoMdEye
                            onClick={showPasswordHandler}
                            className="showPassIcon"
                          />
                        ) : (
                          <IoIosEyeOff
                            onClick={showPasswordHandler}
                            className="showPassIcon"
                          />
                        )}
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className={
                            !loading
                              ? "btn w-100 text-white"
                              : "btn w-100 text-white btn_blur"
                          }
                          style={{
                            padding: "8px",
                            backgroundColor: `var(--primary-color)`,
                          }}
                          onClick={createUserHandler}
                        >
                          {!loading ? (
                            url?.includes("/edit") ? (
                              "Update User"
                            ) : (
                              "Create User"
                            )
                          ) : (
                            <Loader />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
