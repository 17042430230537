import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";

const useGetAllInfluencers = (
  name,
  userName,
  quality,
  primaryCat,
  gender,
  influencerType,
  locations,
  followers,
  avgViews,
  fromDate,
  toDate,
  isGridView,
  typeOfUpload
) => {
  const [allInfluencers, setAllInfluencers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const getData = async (reset = false) => {
    try {
      setIsLoading(true);

      const response = await fetch(
        `${baseUrl}/all-influencers?page=${page}&limit=${
          isGridView ? 20 : Infinity
        }&name=${name}&Username=${userName}&Quallity=${quality}&primaryCategory=${primaryCat}&Gender=${gender}&Type=${influencerType}&Location=${locations}&Followers=${followers}&avgViews=${avgViews}&fromDate=${fromDate}&toDate=${toDate}&uploadType=${typeOfUpload}`
      );

      const data = await response.json();
      if (data && data.influencers) {
        setAllInfluencers((prev) =>
          reset ? data.influencers : [...prev, ...data.influencers]
        );
        setTotalPages(data.totalPages);
      } else {
        setAllInfluencers([]);
      }
    } catch (error) {
      console.error(error);
      setAllInfluencers([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    getData(true);
  }, [
    name,
    userName,
    quality,
    primaryCat,
    gender,
    influencerType,
    locations,
    followers,
    avgViews,
    fromDate,
    toDate,
    isGridView,
    typeOfUpload,
  ]);

  useEffect(() => {
    if (!isGridView) {
      setPage(1);
    }
  }, [isGridView]);

  const handleInfiniteScroll = async () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight &&
      page < totalPages &&
      !isLoading
    ) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (page > 1) {
      getData();
    }
  }, [page]);

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);

    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, [page, totalPages, isLoading]);

  const refreshData = async () => {
    setPage(1);
    await getData(true);
  };

  return [isLoading, allInfluencers, refreshData];
};

export default useGetAllInfluencers;
