import React, { useEffect, useState } from "react";
import AllCampaignTable from "../campaigns/AllCampaignTable";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { toast } from "react-toastify";

const TopInfluencers = () => {
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const getCampaigns = async () => {
    const res = await axios.get(
      `${baseUrl}/all-campaigns?campaignStatus=active`
    );
    setAllCampaigns(res.data);
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  const handleSelectionChange = (newSelection) => {
    setSelectedCampaign(newSelection);
  };

  const handleDeleteSelected = () => {
    handleDelete(selectedCampaign);
  };

  const notifySuccess = (message) =>
    toast.success(message || "Deleted successfully!");
  const notifyError = (error) => toast.error(error);

  const handleDelete = async (idsToDelete) => {
    try {
      await axios.delete(`${baseUrl}/campaign`, {
        data: { ids: idsToDelete },
        headers: {
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Deleted successfully!");

      setAllCampaigns((prev) =>
        prev?.filter((campaign) => !idsToDelete?.includes(campaign?._id))
      );
      setSelectedCampaign([]);
    } catch (error) {
      console.error("Error deleting files:", error);
      notifyError("Error deleting files");
    }
  };

  return (
    <div className="top_infl mt-3">
      <div className="d-flex align-items-center justify-content-between">
        <h6>Active Campaigns</h6>
      </div>
      <div className="table_box">
        <AllCampaignTable
          allCampaigns={allCampaigns}
          notifySuccess={notifySuccess}
          notifyError={notifyError}
          setAllCampaigns={setAllCampaigns}
          setSelectedCampaign={setSelectedCampaign}
          selectedCampaign={selectedCampaign}
          handleSelectionChange={handleSelectionChange}
          handleDeleteSelected={handleDeleteSelected}
          padding={"0"}
        />
      </div>
    </div>
  );
};

export default TopInfluencers;
