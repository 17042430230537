import React, { useState, useEffect } from "react";
import ScrollToTop from "../ScrollToTop";
import Sidebar from "../Sidebar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { Link, useParams } from "react-router-dom";
import {
  BULK_UPLOAD_DATA,
  CAMPAIGN_PERMISSIONS,
  DATA_ENTRY_PERMISSIONS,
  INFLUENCERS_FILTERS,
  SETTINGS,
} from "../../utils/constants";
import { IoIosArrowRoundBack } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";

const UserAccess = ({ onLogout }) => {
  const params = useParams();
  const id = params.id;
  const [checkboxes, setCheckboxes] = useState({
    showFullDashboard: false,
    addData: false,
    editData: false,
    viewDataOnly: false,
    searchByName: false,
    searchByUsername: false,
    searchByLocation: false,
    sortByFollowers: false,
    sortByQuality: false,
    sortByInfluencerType: false,
    sortByCategory: false,
    sortByGender: false,
    sortByAvgView: false,
    uploadFile: false,
    deleteFile: false,
    viewCampaign: false,
    updateCampaign: false,
    deleteCampaign: false,
    createCampaign: false,
    settings: false,
  });
  const [selectedUser, setSelectedUser] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${baseUrl}/user-data/${id}`);
        setSelectedUser(response.data);
        setCheckboxes(response.data.permissions);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    if (id) {
      fetchUser();
    }
  }, [id]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes({
      ...checkboxes,
      [name]: checked,
    });
  };

  const notifySuccess = () =>
    toast.success("Permissions updated successfully!");
  const notifyError = (err) => toast.error(err);

  const handleSave = async () => {
    if (!selectedUser) {
      notifyError("User data not loaded yet.");
      return;
    }
    try {
      await axios.put(`${baseUrl}/update-role-permissions`, {
        userId: id,
        permissions: checkboxes,
      });
      notifySuccess();
    } catch (error) {
      console.error("Error updating permissions:", error);
      notifyError("Failed to update permissions. Please try again.");
    }
  };

  return (
    <div className="wrapper">
      <Sidebar onLogout={onLogout} />
      <ScrollToTop />
      <div
        className="main-panel"
        style={{ backgroundColor: "rgb(244, 248, 251)" }}
      >
        <ToastContainer />
        <div className="dashboard_main">
          <div className="dashboard_header">
            <Link to={"/users"} className="go_back">
              <IoIosArrowRoundBack className="icon" />
            </Link>
            <h4>Update User Access</h4>
          </div>
          <div className="add_data_main mt-3">
            <div className="form_box mt-4">
              <h6>Dashboard</h6>
              <FormGroup className="d-flex flex-wrap flex-row">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showFullDashboard"
                      checked={checkboxes?.showFullDashboard}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Show full dashboard"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                />
              </FormGroup>
              <h6 className="mt-3">Data Entry</h6>
              <FormGroup className="d-flex flex-wrap flex-row">
                {DATA_ENTRY_PERMISSIONS.map((item) => (
                  <FormControlLabel
                    key={item.name}
                    control={
                      <Checkbox
                        name={item.name}
                        checked={checkboxes[item.name]}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={item.label}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  />
                ))}
              </FormGroup>
              <h6 className="mt-3">Bulk Upload</h6>
              <FormGroup className="d-flex flex-wrap flex-row">
                {BULK_UPLOAD_DATA.map((item) => (
                  <FormControlLabel
                    key={item.name}
                    control={
                      <Checkbox
                        name={item.name}
                        checked={checkboxes[item.name]}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={item.label}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  />
                ))}
              </FormGroup>
              <h6 className="mt-3">View Influencers</h6>
              <FormGroup className="d-flex flex-wrap flex-row">
                {INFLUENCERS_FILTERS.map((item) => (
                  <FormControlLabel
                    key={item.name}
                    control={
                      <Checkbox
                        name={item.name}
                        checked={checkboxes[item.name]}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={item.label}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  />
                ))}
              </FormGroup>
              <h6 className="mt-3">Campaign</h6>
              <FormGroup className="d-flex flex-wrap flex-row">
                {CAMPAIGN_PERMISSIONS.map((item) => (
                  <FormControlLabel
                    key={item.name}
                    control={
                      <Checkbox
                        name={item.name}
                        checked={checkboxes[item.name]}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={item.label}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  />
                ))}
              </FormGroup>
              <h6 className="mt-3">Settings</h6>
              <FormGroup className="d-flex flex-wrap flex-row">
                {SETTINGS.map((item) => (
                  <FormControlLabel
                    key={item.name}
                    control={
                      <Checkbox
                        name={item.name}
                        checked={checkboxes[item.name]}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={item.label}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  />
                ))}
              </FormGroup>
            </div>
            <button onClick={handleSave} className="create_user mt-4">
              Save Permissions
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccess;
