import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";

const useGetTotalInfluencers = () => {
  const [allInfluencers, setAllInfluencers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    try {
      const response = await fetch(`${baseUrl}/influencers-without-pagination`);
      const data = await response.json();
      if (data) {
        setAllInfluencers(data);
      } else {
        setAllInfluencers([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setAllInfluencers([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return [isLoading, allInfluencers];
};

export default useGetTotalInfluencers;
