import React from "react";
import Sidebar from "../Sidebar";
import ScrollToTop from "../ScrollToTop";
import "./Settings.scss";
import SettingSidebar from "./SettingSidebar";
import BasicInfo from "./BasicInfo";

const Settings = ({ onLogout }) => {
  return (
    <div className="home_main">
      <ScrollToTop />
      <div className="wrapper">
        <Sidebar onLogout={onLogout} />
        <div className="main-panel" style={{ backgroundColor: "#f4f8fb" }}>
          <div className="dashboard_main">
            <div className="infl_header_top">
              <div className="dashboard_header infl_head">
                <h4>Settings</h4>
              </div>
            </div>
            <div className="row" style={{ marginTop: "34px" }}>
              <div className="col-md-3">
                <SettingSidebar />
              </div>
              <div className="col-md-9 mt-3">
                <BasicInfo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
