import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ScrollToTop from "../ScrollToTop";
import Sidebar from "../Sidebar";
import { FaUserPlus } from "react-icons/fa6";
import "./UserList.scss";
import { DataGrid } from "@mui/x-data-grid";
import { MdDelete, MdEdit, MdSecurity } from "react-icons/md";
import { Switch, Tooltip } from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { useSelector } from "react-redux";

const UsersList = ({ onLogout }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const deleteByUser = useSelector((store) => store.user.userData.name);

  const getUsers = async () => {
    try {
      const res = await axios.get(`${baseUrl}/all-users`);

      if (res?.data?.message === "no user found" || res?.data.length === 0) {
        notifyError("No users found");
        setAllUsers([]);
      } else {
        setAllUsers(res.data);
      }
    } catch (error) {
      notifyError("Error fetching users");
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date.getTime())) {
      return "...";
    }

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedDateTime = `${hours}:${formattedMinutes} ${period}, ${day} ${month} ${year}`;

    return formattedDateTime;
  };

  const columns = [
    { field: "name", headerName: "Name", width: 160 },
    { field: "userName", headerName: "Username", width: 180 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "lastActive",
      headerName: "Last Active",
      width: 170,
      renderCell: (params) => formatDateTime(params?.row?.lastActive),
    },
    {
      field: "takeActions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => (
        <div className="actions">
          <Tooltip title="Give Access" placement="top">
            <Link to={`/users/user-access/${params?.row?.id}`}>
              <button className="btn btn_access p-0">
                <MdSecurity />
              </button>
            </Link>
          </Tooltip>
          <Tooltip title="Edit" placement="top">
            <Link to={`/users/edit-user/${params?.row?.id}`}>
              <button className="btn btn_edit p-0 ms-3">
                <MdEdit />
              </button>
            </Link>
          </Tooltip>
          <Tooltip
            title={!params?.row?.disable ? "Disable" : "Enable"}
            placement="top"
          >
            <button
              className="btn btn_edit p-0 ms-1"
              onClick={() =>
                disableUserHandler(params?.row?.id, params?.row?.disable)
              }
            >
              <Switch {...label} size="small" checked={!params?.row?.disable} />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rows = allUsers
    ?.filter((item) => item?.email !== "admin@solebs.com")
    ?.map((user) => ({
      id: user?._id,
      name: user?.name,
      userName: user?.username,
      email: user?.email,
      lastActive: user?.lastActive,
      disable: user?.disable,
      takeActions: "",
    }));

  const handleSelectionChange = (newSelection) => {
    setSelectedUsers(newSelection);
  };

  const handleDeleteSelected = () => {
    handleDelete(selectedUsers);
  };

  const notifySuccess = (message) =>
    toast.success(message || "Operation successful!");
  const notifyError = (error) => toast.error(error);

  const handleDelete = async (idsToDelete) => {
    try {
      await axios.delete(`${baseUrl}/user-data`, {
        data: { ids: idsToDelete, deletedBy: deleteByUser },
        headers: {
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Users moved to trash successfully!");

      setAllUsers((prev) =>
        prev?.filter((user) => !idsToDelete?.includes(user?._id))
      );
      setSelectedUsers([]);
    } catch (error) {
      console.error("Error deleting files:", error);
      notifyError("Error deleting files");
    }
  };

  const disableUserHandler = async (id, currentState) => {
    const updatedUsers = allUsers.map((user) =>
      user._id === id ? { ...user, disable: !currentState } : user
    );

    setAllUsers(updatedUsers);

    try {
      const response = await axios.patch(`${baseUrl}/user-data/${id}`, {
        disable: !currentState,
      });

      if (response.status === 200) {
        notifySuccess(
          `User ${!currentState ? "disabled" : "enabled"} successfully!`
        );
      } else {
        throw new Error("Failed to update the user status");
      }
    } catch (error) {
      setAllUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === id ? { ...user, disable: currentState } : user
        )
      );
      notifyError("Error updating user status");
    }
  };

  return (
    <div className="wrapper">
      <Sidebar onLogout={onLogout} />
      <ScrollToTop />
      <div
        className="main-panel"
        style={{ backgroundColor: "rgb(244, 248, 251)" }}
      >
        <ToastContainer theme="colored" position="bottom-right" />
        <div className="dashboard_main">
          <div className="dashboard_header">
            <h4>Users List</h4>
            <Link className="create_user" to={"/users/create-user"}>
              Create User <FaUserPlus className="icon" />
            </Link>
          </div>
          <div className="add_data_main mt-3">
            <div className="form_box">
              <div style={{ height: 400, width: "100%", position: "relative" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={handleSelectionChange}
                />
                {selectedUsers.length > 0 && (
                  <div className="selected_row_delete">
                    <button
                      className="btn btn_delete2"
                      onClick={handleDeleteSelected}
                    >
                      <MdDelete />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
