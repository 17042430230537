import React from "react";

const ShimmerBody = ({ height }) => {
  return (
    <div
      className="profile_highlights shimmer_highlights shimmer"
      style={{ height: height }}
    ></div>
  );
};

export default ShimmerBody;
