import React from "react";
import { FaRegUser } from "react-icons/fa6";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { MdOutlineBackup } from "react-icons/md";
import { RiDeleteBin6Line, RiLockPasswordLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const SettingSidebar = () => {
  return (
    <div
      className="filter_left setting_sidebar mt-3"
      style={{ height: "86vh", width: "19%" }}
    >
      <ul className="nav">
        <li className="nav-item">
          <NavLink to={"/settings/basic-informations"} className="nav-link">
            <FaRegUser className="icon" /> Basic Information
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={"/settings/password"} className="nav-link">
            <RiLockPasswordLine className="icon" /> Password
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={"/settings/backup-and-recovery"} className="nav-link">
            <MdOutlineBackup className="icon" /> Backup and recovery
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={"/settings/trash"} className="nav-link">
            <RiDeleteBin6Line className="icon" /> Trash
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SettingSidebar;
