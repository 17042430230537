import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";

const useGetAllCampaigns = (campaignStatus) => {
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${baseUrl}/all-campaigns?campaignStatus=${campaignStatus}`
      );
      setAllCampaigns(res.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [campaignStatus]);
  return [isLoading, allCampaigns];
};

export default useGetAllCampaigns;
