import React from "react";
import SettingSidebar from "./SettingSidebar";
import Sidebar from "../Sidebar";
import ScrollToTop from "../ScrollToTop";
import backupImg from "../../assets/backup.png";
import { FaArrowsRotate } from "react-icons/fa6";

const BackupRecovery = ({ onLogout }) => {
  return (
    <div className="home_main">
      <ScrollToTop />
      <div className="wrapper">
        <Sidebar onLogout={onLogout} />
        <div className="main-panel" style={{ backgroundColor: "#f4f8fb" }}>
          <div className="dashboard_main">
            <div className="infl_header_top">
              <div className="dashboard_header infl_head">
                <h4>Settings</h4>
              </div>
            </div>
            <div className="row" style={{ marginTop: "34px" }}>
              <div className="col-md-3">
                <SettingSidebar />
              </div>
              <div className="col-md-9 mt-3">
                <div className="setting_body">
                  <div className="body text-center">
                    <img
                      src={backupImg}
                      alt="backupImg"
                      className="backup_img mt-5"
                    />
                    <h6 className="mt-4">Backup Now</h6>
                    <p className="light_text">
                      Backup all your influencers data to keep in safe place.
                    </p>
                    <button className="mt-4 backup_btn">
                      <FaArrowsRotate /> Backup
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackupRecovery;
