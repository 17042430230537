export const QUALITIES = [
  "All",
  "Premium",
  "Average",
  "Good",
  "Above Average",
  "Below Average",
  "Bad",
  "Invalid",
];
export const GENDER = ["All", "Male", "Female", "Other / Prefer not to say"];
export const INFLUENCER_TYPE = [
  "Nano",
  "Micro",
  "Mid Tier",
  "Macro",
  "Mega",
  "Celebrity",
];

export const CAMPAIGN_STATUS = [
  "Active",
  "On hold",
  "Completed",
  "Not started yet",
];

export const INFLUENCER_CATEGORY = [
  "Fashion",
  "Beauty",
  "Travel",
  "Lifestyle",
  "Food",
  "Technology",
  "Fitness",
  "Entertainment",
  "Parenting",
  "Luxury",
  "Sports",
];

export const AVG_VIEWS = [
  "All",
  "Below 5k",
  "Below 15k",
  "15-50k",
  "Above 50k",
  "Above 100k",
  "Above 200k",
];

export const FOLLOWERS = [
  { label: "All" },
  { label: "2000-10k", value: "2000-10000" },
  { label: "10k-50k", value: "10000-50000" },
  { label: "50k-200k", value: "50000-200000" },
  { label: "200k-500k", value: "200000-500000" },
  { label: "500k-1M", value: "500000-1000000" },
  { label: "Above 1M", value: "1000000" },
];

export const FAQ = [
  {
    id: 1,
    title: "Do I have to allow the use of cookies?",
    info: "Unicorn vinyl poutine brooklyn, next level direct trade iceland. Shaman copper mug church-key coloring book, whatever poutine normcore fixie cred kickstarter post-ironic street art.",
  },
  {
    id: 2,
    title: "How do I change my My Page password?",
    info: "Coloring book forage photo booth gentrify lumbersexual. Migas chillwave poutine synth shoreditch, enamel pin thundercats fashion axe roof party polaroid chartreuse.",
  },
  {
    id: 3,
    title: "What is BankID?",
    info: "Enamel pin fam sustainable woke whatever venmo. Authentic asymmetrical put a bird on it, lumbersexual activated charcoal kinfolk banjo cred pickled sartorial.",
  },
  {
    id: 4,
    title: "Whose birth number can I use?",
    info: "Edison bulb direct trade gentrify beard lo-fi seitan sustainable roof party franzen occupy squid. Knausgaard cronut succulents, scenester readymade shabby chic lyft. Copper mug meh vegan gentrify.",
  },
  {
    id: 5,
    title: "When do I recieve a password ordered by letter?",
    info: "Locavore franzen fashion axe live-edge neutra irony synth af tilde shabby chic man braid chillwave waistcoat copper mug messenger bag. Banjo snackwave blog, microdosing thundercats migas vaporware viral lo-fi seitan ",
  },
];

export const INFLUENCERS_FILTERS = [
  { name: "viewDataOnly", label: "View data only" },
  { name: "exportData", label: "Export data" },
  { name: "searchByName", label: "Search by name" },
  { name: "searchByUsername", label: "Search by username" },
  { name: "searchByLocation", label: "Search by location" },
  { name: "sortByFollowers", label: "Sort by followers" },
  { name: "sortByQuality", label: "Sort by quality" },
  { name: "sortByInfluencerType", label: "Sort by influencer type" },
  { name: "sortByCategory", label: "Sort by category" },
  { name: "sortByGender", label: "Sort by gender" },
  { name: "sortByAvgView", label: "Sort by avg views" },
];

export const DATA_ENTRY_PERMISSIONS = [
  { name: "addData", label: "Add data" },
  { name: "editData", label: "Edit data" },
  { name: "deleteData", label: "Delete data" },
];

export const BULK_UPLOAD_DATA = [
  { name: "uploadFile", label: "Upload file" },
  { name: "deleteFile", label: "Delete file" },
];

export const CAMPAIGN_PERMISSIONS = [
  { name: "createCampaign", label: "Create Campaign" },
  { name: "deleteCampaign", label: "Delete Campaign" },
  { name: "updateCampaign", label: "Update Campaign" },
  { name: "viewCampaign", label: "View Campaign" },
];

export const SETTINGS = [{ name: "settings", label: "Settings" }];
