import React, { useEffect, useRef } from "react";
import profileMale from "../../assets/user-male.png";
import { baseUrl } from "../../apiConfig";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const BasicInfo = () => {
  const id = useSelector((store) => store.user.userData._id);
  const userData = useSelector((store) => store.user.userData);
  const name = useRef(null);
  const username = useRef(null);
  const email = useRef(null);
  const notifySuccess = () => toast.success("Profile updated");

  useEffect(() => {
    name.current.value = userData?.name;
    username.current.value = userData?.username;
    email.current.value = userData?.email;
  }, [userData]);

  const updateUserData = async () => {
    const data = {
      name: name.current.value,
      username: username.current.value,
      email: email.current.value,
    };
    try {
      const response = await fetch(`${baseUrl}/user-data/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "Network response was not ok");
      }
      notifySuccess();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="setting_body">
      <ToastContainer theme="colored" position="bottom-right" />
      <div className="cover">
        <div className="profile_pic">
          <img
            src={userData?.profilePic ? userData?.profilePic : profileMale}
            alt="profile"
          />
        </div>
      </div>
      <h4 className="text-center mt-5">{userData?.name}</h4>
      <div className="body">
        <h5>Edit your account information:</h5>
        <div className="edit_info">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="col-md-6">
                <input ref={name} type="text" placeholder="Name" />
              </div>
              <div className="col-md-6">
                <input ref={username} type="text" placeholder="Username" />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <input ref={email} type="email" placeholder="Email" />
              </div>
              <div className="col-md-6">
                <input type="text" placeholder="Location" />
              </div>
            </div>
            <div className="row mt-4">
              <textarea placeholder="About you/Bio" rows={5}></textarea>
            </div>
            <button className="btn_save mt-4" onClick={updateUserData}>
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
