import React, { useRef, useState } from "react";
import loginLeft from "../assets/login-left-icon.svg";
import loginRight from "../assets/login-right-icon.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoIosEyeOff, IoMdEye } from "react-icons/io";
import { baseUrl } from "../apiConfig";
import { useDispatch } from "react-redux";
import { addUser, removeUser } from "../utils/userSlice";
import { jwtDecode } from "jwt-decode";

const Login = ({ onLogin }) => {
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const password = useRef(null);
  const email = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getData = async (id) => {
    const response = await fetch(`${baseUrl}/user-data/${id}`);
    const data = await response.json();
    return data;
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(removeUser);
    navigate("/login");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/login`, {
        email: email.current.value,
        password: password.current.value,
      });

      if (response.data.token && response.data.userId) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userId", response.data.userId);
        const userData = await getData(response.data.userId);
        localStorage.setItem("userData", JSON.stringify(userData));
        const decodedToken = jwtDecode(response.data.token);
        const expiryTime = decodedToken.exp * 1000 - Date.now();

        setTimeout(() => {
          handleLogout();
        }, expiryTime);
        dispatch(
          addUser({
            token: response.data.token,
            userData,
          })
        );

        setError("");
        onLogin();
        if (email.current.value === "admin@solebs.com") {
          navigate("/");
        } else {
          navigate("/influencers");
        }
      } else {
        setError("Failed to retrieve user data");
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data);
      } else {
        setError("An unexpected error occurred");
      }
    }
  };

  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${baseUrl}/login`, {
  //       email: email.current.value,
  //       password: password.current.value,
  //     });
  //     if (response.data.token && response.data.userId) {
  //       setError("");
  //       onLogin();
  //       const userData = await getData(response.data.userId);

  //       dispatch(
  //         addUser({
  //           token: response.data.token,
  //           userData,
  //         })
  //       );
  //       navigate("/");
  //     } else {
  //       setError("Failed to retrieve user data");
  //     }
  //   } catch (err) {
  //     setError("Invalid credentials");
  //   }
  // };

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   const token = params.get("token");
  //   const userId = params.get("userId");

  //   if (token && userId) {
  //     localStorage.setItem("token", token);
  //     localStorage.setItem("userId", userId);
  //     onLogin();
  //     navigate("/");
  //   }
  // }, [navigate, onLogin]);

  const showPasswordHandler = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <section className="admin_login">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3">
            <div className="login-left-icon">
              <img src={loginLeft} alt="login shape" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-container text-center login_form">
              <div className="login_header pt-0">
                <h3>User Login</h3>
                <p>
                  Hey, Enter your details to get sign in
                  <br />
                  to your account
                </p>
              </div>
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <input
                    ref={email}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
                <div className="form-group position-relative">
                  <input
                    ref={password}
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                  />
                  {!showPassword ? (
                    <IoMdEye
                      onClick={showPasswordHandler}
                      className="showPassIcon"
                    />
                  ) : (
                    <IoIosEyeOff
                      onClick={showPasswordHandler}
                      className="showPassIcon"
                    />
                  )}
                </div>
                {error && <p style={{ color: "red" }}>{error}</p>}
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    style={{ padding: "8px" }}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-3">
            <div className="login-left-icon">
              <img src={loginRight} alt="login shape" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
