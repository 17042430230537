import React from "react";
import { HiUsers } from "react-icons/hi2";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { MdCampaign } from "react-icons/md";
import { PiHandshakeFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useGetAllCampaigns from "../../utils/useGetAllCampaigns";

const Overview = () => {
  const totalInfluencers = useSelector(
    (store) => store.influencers.influencersList?.total
  );

  const [, allCampaigns] = useGetAllCampaigns("active");

  return (
    <div className="overview">
      <Link to={"/influencers"}>
        <div className="card w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p className="big_text">{totalInfluencers?.toLocaleString()}</p>
              <p className="small_text">Total Influencers</p>
            </div>
            <div
              className="users"
              style={{ backgroundColor: "var(--primary-color)" }}
            >
              <HiUsers />
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2 progress_text">
            <p>0%</p>
            <p>50%</p>
          </div>
          <div
            className="progress"
            role="progressbar"
            aria-label="Info striped example"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              className="progress-bar progress-bar-striped"
              style={{
                width: "50%",
                backgroundColor: "var(--primary-color)",
              }}
            ></div>
          </div>
        </div>
      </Link>
      <Link>
        <div className="card w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p className="big_text">{allCampaigns?.length}</p>
              <p className="small_text">Active Campaigns</p>
            </div>
            <div className="users" style={{ backgroundColor: "#80C4E9" }}>
              <MdCampaign />
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2 progress_text">
            <p>0%</p>
            <p>40%</p>
          </div>
          <div
            className="progress"
            role="progressbar"
            aria-label="Info striped example"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              className="progress-bar progress-bar-striped"
              style={{
                width: "40%",
                backgroundColor: "#80C4E9",
              }}
            ></div>
          </div>
        </div>
      </Link>
      <Link>
        <div className="card w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p className="big_text">18</p>
              <p className="small_text">Total Reach</p>
            </div>
            <div className="users" style={{ backgroundColor: "#fdd69f" }}>
              <PiHandshakeFill />
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2 progress_text">
            <p>0%</p>
            <p>40%</p>
          </div>
          <div
            className="progress"
            role="progressbar"
            aria-label="Info striped example"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              className="progress-bar progress-bar-striped"
              style={{
                width: "40%",
                backgroundColor: "#fdd69f",
              }}
            ></div>
          </div>
        </div>
      </Link>
      <Link>
        <div className="card w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p className="big_text">₹ 12,567</p>
              <p className="small_text">Budget Spent</p>
            </div>
            <div className="users" style={{ backgroundColor: "#604CC3" }}>
              <FaMoneyCheckDollar />
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2 progress_text">
            <p>0%</p>
            <p>40%</p>
          </div>
          <div
            className="progress"
            role="progressbar"
            aria-label="Info striped example"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              className="progress-bar progress-bar-striped"
              style={{
                width: "40%",
                backgroundColor: "#604CC3",
              }}
            ></div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Overview;
