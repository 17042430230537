import React from "react";
import SettingSidebar from "./SettingSidebar";
import Sidebar from "../Sidebar";
import ScrollToTop from "../ScrollToTop";
import { Paper, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { MdDelete, MdRestore } from "react-icons/md";
import useGetTrashItems from "../../utils/useGetTrashItems";

const TrashedItems = ({ onLogout }) => {
  const [trashItems] = useGetTrashItems();
  const columns = [
    { field: "name", headerName: "Name", width: 130 },
    { field: "deletedBy", headerName: "Deleted by", width: 130 },
    {
      field: "time",
      headerName: "Time",
      width: 130,
    },
    {
      field: "date",
      headerName: "Date",
      width: 160,
    },
    {
      field: "takeActions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => (
        <div className="actions">
          <Tooltip title="Restore" placement="top">
            <button className="btn btn_access p-0">
              <MdRestore />
            </button>
          </Tooltip>
          <Tooltip title="Delete Permanently" placement="top">
            <button className="btn btn_edit p-0 ms-3">
              <MdDelete />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rows = trashItems?.map((item) => ({
    id: item?._id,
    deletedBy: item?.deletedBy,
    name: item?.itemData?.name || item?.itemData?.Name,
    time: item?.time,
    date: item?.date,
  }));

  const paginationModel = { page: 0, pageSize: 5 };

  return (
    <div className="home_main">
      <ScrollToTop />
      <div className="wrapper">
        <Sidebar onLogout={onLogout} />
        <div className="main-panel" style={{ backgroundColor: "#f4f8fb" }}>
          <div className="dashboard_main">
            <div className="infl_header_top">
              <div className="dashboard_header infl_head">
                <h4>Settings</h4>
              </div>
            </div>
            <div className="row" style={{ marginTop: "34px" }}>
              <div className="col-md-3">
                <SettingSidebar />
              </div>
              <div className="col-md-9 mt-3">
                {/* <div className="setting_body p-5">
                  
                </div> */}
                <Paper sx={{ height: "86vh", width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                    sx={{ border: 0 }}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrashedItems;
