import { Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { MdEdit, MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { IoEyeOutline } from "react-icons/io5";
import ScrollToTop from "../ScrollToTop";
import InstagramProfile from "../InstagramProfile";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const TableView = ({ isLoading, allInfluencers, refreshData }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const deleteByUser = useSelector((store) => store.user.userData.name);

  const handleSelectionChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const handleDeleteSelected = async () => {
    try {
      await Promise.all(
        selectedRows.map((id) =>
          axios.delete(`${baseUrl}/all-influencers/${id}`, {
            data: { deletedBy: deleteByUser },
          })
        )
      );
      refreshData();
      setSelectedRows([]);
    } catch (error) {
      console.error("Error deleting influencers:", error);
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "username", headerName: "Username", width: 150 },
    {
      field: "followers",
      headerName: "Followers",
      width: 100,
    },
    {
      field: "email",
      headerName: "Email",
      width: 160,
    },
    {
      field: "category",
      headerName: "Primary Category",
      width: 160,
    },
    {
      field: "mobileNum",
      headerName: "Mobile Number",
      width: 160,
    },
    {
      field: "whatsappNum",
      headerName: "Whatsapp Number",
      width: 160,
    },
    {
      field: "influencerType",
      headerName: "Influencer Type",
      width: 160,
    },
    {
      field: "quallity",
      headerName: "Quality",
      width: 160,
    },
    {
      field: "avgViews",
      headerName: "Avg Views",
      width: 160,
    },
    {
      field: "location",
      headerName: "Location",
      width: 160,
    },
    {
      field: "address",
      headerName: "Address",
      width: 160,
    },
    {
      field: "instaProfile",
      headerName: "Actions",
      width: 170,
      renderCell: (params) => (
        <div className="actions">
          <Tooltip title="View Insta Profile" placement="top">
            <a href={params.value} target="_blank" rel="noopener noreferrer">
              <FaInstagram style={{ marginRight: 8 }} />
            </a>
          </Tooltip>
          <Tooltip title="View Full Profile" placement="top">
            <Link to={`/influencer-profile/${params?.row?.id}`}>
              <button className="btn btn_edit p-0 ms-3">
                <IoEyeOutline />
              </button>
            </Link>
          </Tooltip>
          <Tooltip title="Edit" placement="top">
            <Link to={`/edit/${params?.row?.id}`}>
              <button className="btn btn_edit p-0 ms-3">
                <MdEdit />
              </button>
            </Link>
          </Tooltip>
          <InstagramProfile
            username={params?.row?.username}
            id={params?.row?.id}
          />
        </div>
      ),
    },
  ];

  const rows = allInfluencers?.map((infl) => ({
    id: infl?._id,
    name: infl?.Name,
    username: infl?.Username,
    followers: infl?.Followers,
    email: infl?.Email,
    category: infl?.primaryCategory[0],
    mobileNum: infl?.contactNum,
    whatsappNum: infl?.whatsappNum,
    influencerType: infl?.Type,
    quallity: infl?.Quallity,
    avgViews: infl?.avgViews,
    location: infl?.Location,
    address: infl?.Address,
    instaProfile: infl?.instagramProfile ? infl?.instagramProfile : "NA",
  }));

  return (
    <div
      style={{
        minHeight: "400px",
        height: "650px",
        width: "100%",
        position: "relative",
      }}
      className="mt-3"
    >
      <ScrollToTop />
      <ToastContainer />
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, { label: "All", value: -1 }]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={handleSelectionChange}
      />
      {selectedRows.length > 0 && (
        <div className="selected_row_delete">
          <Tooltip title="Delete Selected Influencers" placement="top">
            <button className="btn btn_delete2" onClick={handleDeleteSelected}>
              <MdDelete />
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default TableView;
