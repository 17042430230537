import React, { useState } from "react";
import Sidebar from "../Sidebar";
import "./Influencers.scss";
import useGetAllInfluencers from "../../utils/useGetAllInfluencers";
import ScrollToTop from "../ScrollToTop";
import TopFilters from "./TopFilters";
import LeftFilters from "./LeftFilters";
import InfluencersBody from "./InfluencersBody";
import { FaList } from "react-icons/fa6";
import { BsGrid3X3GapFill } from "react-icons/bs";
import TableView from "./TableView";

const Influencers = ({ onLogout }) => {
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [location, setLocation] = useState("");
  const [followers, setFollowers] = useState("");
  const [quality, setQuality] = useState("");
  const [gender, setGender] = useState("");
  const [avgViews, setAvgViews] = useState("");
  const [primaryCat, setPrimaryCat] = useState([]);
  const [influencerType, setInfluencerType] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isGridView, setIsGridView] = useState(true);
  const [typeOfUpload, setTypeOfUpload] = useState("All");

  const handleInfluencerTypeChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setInfluencerType((prev) => [...prev, value]);
    } else {
      setInfluencerType((prev) => prev.filter((type) => type !== value));
    }
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setPrimaryCat((prev) => [...prev, value]);
    } else {
      setPrimaryCat((prev) => prev.filter((type) => type !== value));
    }
  };

  const [isLoading, allInfluencers, refreshData] = useGetAllInfluencers(
    name,
    userName,
    quality,
    primaryCat,
    gender,
    influencerType,
    location,
    followers,
    avgViews,
    fromDate,
    toDate,
    isGridView,
    typeOfUpload
  );

  const handleViewChange = (view) => {
    setIsGridView(view === "grid");
    if (view === "table") {
      refreshData();
    }
  };

  return (
    <div className="home_main">
      <ScrollToTop />
      <div className="wrapper">
        <Sidebar onLogout={onLogout} />
        <div className="main-panel" style={{ backgroundColor: "#f4f8fb" }}>
          <div className="dashboard_main">
            <div className="infl_header_top">
              <div className="dashboard_header infl_head">
                <h4>Influencers</h4>
                <div className="table_grid">
                  <div
                    className={isGridView ? "active" : ""}
                    onClick={() => handleViewChange("grid")}
                  >
                    <BsGrid3X3GapFill />
                  </div>
                  <div
                    className={!isGridView ? "active" : ""}
                    onClick={() => handleViewChange("table")}
                  >
                    <FaList />
                  </div>
                </div>
              </div>
              <TopFilters
                name={name}
                setName={setName}
                userName={userName}
                setUserName={setUserName}
                location={location}
                setLocation={setLocation}
              />
            </div>
            <div className="row" style={{ marginTop: "123px" }}>
              <div className="col-md-4">
                <LeftFilters
                  followers={followers}
                  setFollowers={setFollowers}
                  handleInfluencerTypeChange={handleInfluencerTypeChange}
                  handleCategoryChange={handleCategoryChange}
                  gender={gender}
                  setGender={setGender}
                  avgViews={avgViews}
                  setAvgViews={setAvgViews}
                  quality={quality}
                  setQuality={setQuality}
                  fromDate={fromDate}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                  typeOfUpload={typeOfUpload}
                  setTypeOfUpload={setTypeOfUpload}
                />
              </div>
              <div className="col-md-8">
                {isGridView ? (
                  <InfluencersBody
                    isLoading={isLoading}
                    allInfluencers={allInfluencers}
                  />
                ) : (
                  <TableView
                    isLoading={isLoading}
                    allInfluencers={allInfluencers}
                    refreshData={refreshData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Influencers;
