import React from "react";
import ScrollToTop from "./ScrollToTop";
import { FaWpforms } from "react-icons/fa6";
import { GrDocumentCsv } from "react-icons/gr";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";

const AddDataHome = ({ onLogout }) => {
  const userPermissions = useSelector(
    (store) => store.user.userData.permissions
  );
  const isAdmin = useSelector((store) => store.user.userData.isAdmin);
  return (
    <div className="wrapper">
      <Sidebar onLogout={onLogout} />
      <ScrollToTop />
      <div className="main-panel">
        <div className="container add_data_main mt-5">
          <div className="add_data_home">
            <h1 className="heading text-center">
              Add Influencers Manually or by Uploading CSV File
            </h1>
            <div className="docs_row">
              <Link to={"/add-data/manually"}>
                <div className="add_data">
                  <div className="img_circle">
                    <FaWpforms />
                  </div>
                  <h5>Add Manually</h5>
                </div>
              </Link>
              {(isAdmin || userPermissions.uploadFile) && (
                <Link to={"/add-data/bulk-upload"}>
                  <div className="add_data">
                    <div className="img_circle img_circle2">
                      <GrDocumentCsv />
                    </div>
                    <h5>Bulk Upload</h5>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDataHome;
