import React, { useEffect, useState } from "react";
import CampaignSidebar from "./CampaignSidebar";
import Sidebar from "../Sidebar";
import ScrollToTop from "../ScrollToTop";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { CAMPAIGN_STATUS } from "../../utils/constants";
import AllCampaignTable from "./AllCampaignTable";
import { useSelector } from "react-redux";

const ViewAllCampaign = ({ onLogout }) => {
  const user = useSelector((store) => store.user);
  const { userData: { permissions: { viewCampaign } = {}, isAdmin } = {} } =
    user || {};
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [campaignStatus, setCampaignStatus] = useState("");
  const getCampaigns = async () => {
    const res = await axios.get(
      `${baseUrl}/all-campaigns?campaignStatus=${campaignStatus}`
    );
    setAllCampaigns(res.data);
  };

  const handleCampaignStatus = (e) => {
    setCampaignStatus(e.target.value);
  };

  useEffect(() => {
    getCampaigns();
  }, [campaignStatus]);

  const handleSelectionChange = (newSelection) => {
    setSelectedCampaign(newSelection);
  };

  const handleDeleteSelected = () => {
    handleDelete(selectedCampaign);
  };

  const notifySuccess = (message) =>
    toast.success(message || "Deleted successfully!");
  const notifyError = (error) => toast.error(error);

  const handleDelete = async (idsToDelete) => {
    try {
      await axios.delete(`${baseUrl}/campaign`, {
        data: { ids: idsToDelete },
        headers: {
          "Content-Type": "application/json",
        },
      });
      notifySuccess("Deleted successfully!");

      setAllCampaigns((prev) =>
        prev?.filter((campaign) => !idsToDelete?.includes(campaign?._id))
      );
      setSelectedCampaign([]);
    } catch (error) {
      console.error("Error deleting files:", error);
      notifyError("Error deleting files");
    }
  };

  return (
    <div className="home_main">
      <ScrollToTop />
      <ToastContainer />
      <div className="wrapper">
        <Sidebar onLogout={onLogout} />
        <div className="main-panel" style={{ backgroundColor: "#f4f8fb" }}>
          <div className="dashboard_main">
            <div className="infl_header_top">
              <div className="dashboard_header infl_head">
                <h4>View All Campaigns</h4>
              </div>
            </div>
            <div className="row" style={{ marginTop: "34px" }}>
              <div className="col-md-3">
                <CampaignSidebar />
              </div>
              <div className="col-md-9 mt-3">
                <div className="setting_body">
                  {(isAdmin || viewCampaign) && (
                    <div className="body d-flex justify-content-between align-items-center">
                      <h6>View All Campaign</h6>
                      <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={campaignStatus}
                          onChange={handleCampaignStatus}
                          size="small"
                          label="Status"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {CAMPAIGN_STATUS.map((camp) => (
                            <MenuItem value={camp} key={camp}>
                              {camp}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  {(isAdmin || viewCampaign) && (
                    <AllCampaignTable
                      allCampaigns={allCampaigns}
                      notifySuccess={notifySuccess}
                      notifyError={notifyError}
                      setAllCampaigns={setAllCampaigns}
                      setSelectedCampaign={setSelectedCampaign}
                      selectedCampaign={selectedCampaign}
                      handleSelectionChange={handleSelectionChange}
                      handleDeleteSelected={handleDeleteSelected}
                      padding={"0 20px"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllCampaign;
