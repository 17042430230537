import React from "react";
import CircleGraph from "./CircleGraph";
import LineGraph from "./LineGraph";

const Graphs = () => {
  return (
    <div className="graphs_main mt-3 d-flex justify-content-between">
      <div className="curve_graph">
        <div className="d-flex align-items-center justify-content-between">
          <h6>Influencers by category</h6>
        </div>
        <LineGraph />
      </div>
      <div className="circle_graph">
        <h6>Influencers by type</h6>
        <CircleGraph />
      </div>
    </div>
  );
};

export default Graphs;
