import React, { useState } from "react";
import profile from "../assets/user-male.png";
import { NavLink } from "react-router-dom";
import { MdArrowDropDown } from "react-icons/md";
import { useSelector } from "react-redux";

const ProfilePic = ({ onLogout }) => {
  const [showDropDown, setShowDropdown] = useState(false);
  const userData = useSelector((store) => store.user.userData);

  const showDropdownHandler = () => {
    setShowDropdown((prev) => !prev);
  };

  return (
    <div className="position-relative">
      <div className="profile" onClick={showDropdownHandler}>
        <img
          src={userData?.profilePic ? userData?.profilePic : profile}
          alt="Profile"
        />
      </div>
      <MdArrowDropDown className="drop_icon" onClick={showDropdownHandler} />
      {showDropDown && (
        <div className="dropdown_main position-absolute">
          <ul>
            {(userData?.permissions?.settings || userData?.isAdmin) && (
              <li>
                <NavLink to={"/settings/basic-informations"}>Settings</NavLink>
              </li>
            )}
            {/* <li className="mt-2">
              <NavLink>Profile</NavLink>
            </li> */}
            {(userData?.permissions?.settings || userData?.isAdmin) && <hr />}
            <li onClick={onLogout} className="sign_out">
              Sign out
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProfilePic;
