import React from "react";
import {
  AVG_VIEWS,
  FOLLOWERS,
  GENDER,
  INFLUENCER_CATEGORY,
  INFLUENCER_TYPE,
  QUALITIES,
} from "../../utils/constants";
import { useSelector } from "react-redux";

const LeftFilters = ({
  followers,
  setFollowers,
  handleInfluencerTypeChange,
  handleCategoryChange,
  gender,
  setGender,
  avgViews,
  setAvgViews,
  quality,
  setQuality,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  typeOfUpload,
  setTypeOfUpload,
}) => {
  const user = useSelector((store) => store.user);
  const {
    userData: {
      permissions: {
        sortByFollowers,
        sortByQuality,
        sortByInfluencerType,
        sortByCategory,
        sortByGender,
        sortByAvgView,
      } = {},
      isAdmin,
    } = {},
  } = user || {};

  return (
    <div className="filter_left mt-3">
      {(isAdmin || sortByFollowers || sortByQuality) && (
        <div className="filter_box mb-3">
          <h6>Sorting by</h6>
          {(isAdmin || sortByFollowers) && (
            <select
              className="form-select"
              onChange={(e) => setFollowers(e.target.value)}
              value={followers}
            >
              <option value="" disabled>
                Followers
              </option>
              {FOLLOWERS.map((item) => (
                <option value={item.value} key={item.label}>
                  {item.label}
                </option>
              ))}
            </select>
          )}
          {(isAdmin || sortByQuality) && (
            <select
              className="form-select mt-3"
              onChange={(e) => setQuality(e.target.value)}
              value={quality}
            >
              <option value="" disabled>
                Quality
              </option>
              {QUALITIES.map((qual) => (
                <option value={qual} key={qual}>
                  {qual}
                </option>
              ))}
            </select>
          )}
        </div>
      )}
      {(isAdmin || sortByInfluencerType) && (
        <div className="filter_box mb-3">
          <h6>Influencer Type</h6>
          {INFLUENCER_TYPE.map((type) => (
            <div className="form-check" key={type}>
              <input
                className="form-check-input"
                type="checkbox"
                value={type}
                onChange={handleInfluencerTypeChange}
                id={`flexCheckDefault-${type}`}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                {type}
              </label>
            </div>
          ))}
        </div>
      )}
      <div className="filter_box filter_date mb-3">
        <h6>Date of upload</h6>
        <form>
          <div>
            <label htmlFor="floatingInputValue1" className="date_label">
              From
            </label>
            <input
              type="date"
              className="form-control"
              id="floatingInputValue1"
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
            />
          </div>
          <div className="mt-3">
            <label htmlFor="floatingInputValue2" className="date_label">
              To
            </label>
            <input
              type="date"
              className="form-control"
              id="floatingInputValue2"
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value);
              }}
            />
          </div>
        </form>
        <h6 className="mt-4">Type of upload</h6>
        {isAdmin && (
          <select
            className="form-select"
            onChange={(e) => setTypeOfUpload(e.target.value)}
            value={typeOfUpload}
          >
            <option value="" disabled>
              Type of upload
            </option>
            <option value="All">All</option>
            <option value="manual">Manual upload</option>
            <option value="Bulk">Bulk upload</option>
          </select>
        )}
      </div>
      {(isAdmin || sortByCategory) && (
        <div className="filter_box mb-3">
          <h6>Category</h6>
          {INFLUENCER_CATEGORY.map((category) => (
            <div className="form-check" key={category}>
              <input
                className="form-check-input"
                type="checkbox"
                value={category}
                id={`flexCheckDefault-${category}`}
                onChange={handleCategoryChange}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                {category}
              </label>
            </div>
          ))}
        </div>
      )}
      {(isAdmin || sortByGender || sortByAvgView) && (
        <div className="filter_box">
          <h6>Sorting by</h6>
          {(isAdmin || sortByGender) && (
            <select
              className="form-select mt-3"
              onChange={(e) => setGender(e.target.value)}
              value={gender}
            >
              <option value="" disabled>
                Gender
              </option>
              {GENDER.map((gender) => (
                <option value={gender} key={gender}>
                  {gender}
                </option>
              ))}
            </select>
          )}
          {(isAdmin || sortByAvgView) && (
            <select
              className="form-select mt-3"
              onChange={(e) => setAvgViews(e.target.value)}
              value={avgViews}
            >
              <option value="" disabled>
                Avg Views
              </option>
              {AVG_VIEWS.map((views) => (
                <option value={views} key={views}>
                  {views}
                </option>
              ))}
            </select>
          )}
        </div>
      )}
    </div>
  );
};

export default LeftFilters;
