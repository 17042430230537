import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";

const useGetTrashItems = () => {
  const [trashItems, setTrashItems] = useState([]);
  const getData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/trash`);
      setTrashItems(response.data);
    } catch (error) {
      console.error("Error fetching trash items:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return [trashItems];
};

export default useGetTrashItems;
