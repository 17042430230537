import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../apiConfig";

const useGetCampaignById = (id) => {
  const [campaignData, setCampaignData] = useState([]);
  const url = window.location.href;

  const getData = async () => {
    const res = await axios.get(`${baseUrl}/campaign/${id}`);
    setCampaignData(res?.data);
  };

  useEffect(() => {
    if (url.includes("/edit-campaign/")) {
      getData();
    }
  }, [id, url]);

  return [campaignData];
};

export default useGetCampaignById;
